<template>
  <div
    data-testid="user-profile"
    class="tw-relative tw-hidden md:tw-block"
    @click="
      () => {
        $analytics.trackElementClicked({ elementType: 'navigation', text: 'profile' })
        toggleDropdown()
      }
    "
  >
    <div class="tw-flex tw-items-center tw-rounded-lg">
      <PersonCircle class="tw-h-5 tw-w-5 tw-cursor-pointer" />
      <span class="tw-pl-2 tw-pr-1">{{ $auth?.user?.firstName }}</span>
      <ChevronDown class="tw-h-3 tw-w-3" />
    </div>
    <div
      v-if="isDropdownOpen"
      class="tw-absolute tw-right-0 tw-top-full tw-z-10 tw-w-auto tw-min-w-[200px] tw-rounded-lg tw-border tw-border-gray-200 tw-bg-white tw-bg-white tw-shadow-lg"
    >
      <NuxtLink
        to="/v2/profile"
        class="tw-flex tw-items-center tw-space-x-2 tw-rounded-t-lg tw-border-b tw-border-gray-200 tw-p-4 hover:tw-cursor-pointer hover:tw-bg-gray-100"
        @click="$analytics.trackElementClicked({ text: 'View profile', elementType: 'navigation' })"
      >
        <PersonIcon class="tw-h-5 tw-w-5 tw-text-gray-600" />
        <span class="tw-w-[130px] tw-text-base tw-font-normal tw-text-gray-800">View profile</span>
        <ChevronRight class="tw-h-5 tw-w-5 tw-text-gray-800" />
      </NuxtLink>

      <T2Button
        class="tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-space-x-3 tw-rounded-b-lg tw-border-none tw-p-4 tw-font-normal tw-text-rose-600 hover:tw-bg-gray-100"
        @click="logout"
      >
        <ArrowExitIcon class="tw-h-5 tw-w-5" />
        <span class="tw-text-base tw-font-medium">Log out</span>
      </T2Button>
    </div>
  </div>
</template>

<script setup>
import T2Button from '@torticity/components/src/T2Button.vue'

import ArrowExitIcon from '~icons/fluent/arrow-exit-20-filled'
import ChevronDown from '~icons/fluent/chevron-down-12-filled'
import ChevronRight from '~icons/fluent/chevron-right-20-filled'
import PersonIcon from '~icons/fluent/person-20-filled'
import PersonCircle from '~icons/fluent/person-circle-20-filled'

const $analytics = useAnalytics()
const { $auth } = useNuxtApp()
const { logout: _logout } = useLogout()

const isDropdownOpen = ref(false)

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

function logout() {
  $analytics.trackElementClicked({ text: 'Log out', elementType: 'navigation' })
  _logout()
}
</script>
