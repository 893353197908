<template>
  <div class="tw-w-full" :class="headerNav">
    <nav class="tw-mx-auto tw-flex tw-w-3/4 tw-justify-between tw-pb-4 tw-pt-3">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-4 lg:tw-justify-start">
        <!-- CaseWise logo always visible - centered on tablet and mobile -->
        <NuxtLink
          :to="'/v2'"
          @click="$analytics.trackElementClicked({ text: 'Logo', elementType: 'navigation' })"
        >
          <CaseWiseHeaderLogo />
        </NuxtLink>
        <div v-if="cobrandedExperience" class="tw-hidden sm:tw-block">
          <TorticityLogo />
        </div>
      </div>
      <!-- Nav Links and avatar - hidden on tablet and mobile -->
      <div class="tw-hidden tw-items-center tw-gap-6 tw-whitespace-nowrap tw-text-sm lg:tw-flex">
        <NuxtLink
          to="/v2"
          class="tw-rounded-lg tw-bg-opacity-80 tw-p-2 hover:tw-bg-white hover:tw-bg-opacity-25 hover:tw-text-white"
          :active-class="activeNavItem"
          @click="$analytics.trackElementClicked({ text: 'Home', elementType: 'navigation' })"
        >
          Home
        </NuxtLink>
        <NuxtLink
          to="/v2/files"
          class="tw-rounded-lg tw-bg-opacity-80 tw-p-2 hover:tw-bg-white hover:tw-bg-opacity-25 hover:tw-text-white"
          :active-class="activeNavItem"
          @click="$analytics.trackElementClicked({ text: 'My files', elementType: 'navigation' })"
        >
          My files
        </NuxtLink>
        <NuxtLink
          to="/v2/help"
          class="tw-rounded-lg tw-bg-opacity-80 tw-p-2 hover:tw-bg-white hover:tw-bg-opacity-25 hover:tw-text-white"
          :active-class="activeNavItem"
          @click="$analytics.trackElementClicked({ text: 'Help', elementType: 'navigation' })"
        >
          Help
        </NuxtLink>
        <V2UserAvatar
          class="tw-rounded-lg tw-p-2"
          :class="
            isActive
              ? activeNavItem
              : 'hover:tw-cursor-pointer hover:tw-bg-white hover:tw-bg-opacity-25 hover:tw-text-white'
          "
          @click="toggleActive"
        />
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import CaseWiseHeaderLogo from '~/assets/icons/CaseWiseHeaderLogo.svg'
import TorticityLogo from '~/assets/icons/WhitePoweredByTorticity.svg'

const { $analytics } = useNuxtApp()

defineProps<{
  cobrandedExperience: boolean
}>()

const { getThemeTokenString } = useTheme()
const activeNavItem = getThemeTokenString('header-active-item')
const headerNav = getThemeTokenString('header-nav')

const isActive = ref(false)

const toggleActive = () => {
  isActive.value = !isActive.value
}
</script>
