<template>
  <NuxtLink
    :key="link.path"
    class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-p-2 tw-text-xs tw-text-gray-500 lg:tw-flex-row lg:tw-gap-y-0"
    :to="`${link.path}`"
  >
    <div class="tw-relative tw-flex tw-w-full tw-flex-col tw-items-center">
      <div
        v-if="isActiveLink(link)"
        class="tw-absolute -tw-top-2 tw-h-0.5 tw-w-full tw-rounded-full"
        :class="activeBarColor"
      />
      <Component
        :is="getLinkIcon(link)"
        class="tw-h-6 tw-w-6"
        :class="isActiveLink(link) ? textColor : ''"
      />
    </div>
    <span :class="isActiveLink(link) ? textColor : ''">
      {{ link.text }}
    </span>
  </NuxtLink>
</template>

<script setup lang="ts">
const route = useRoute()

defineProps<{
  link: {
    path: string
    text: string
    filledIcon: Component
    outlinedIcon: Component
  }
}>()

const { getThemeTokenString } = useTheme()
const textColor = getThemeTokenString('primary-text')
const activeBarColor = getThemeTokenString('bottom-nav-active-bar')

function isActiveLink(link: { path: string }) {
  return route.path.endsWith(link.path)
}

function getLinkIcon(link: { path: string; filledIcon: Component; outlinedIcon: Component }) {
  return isActiveLink(link) ? link.filledIcon : link.outlinedIcon
}
</script>
