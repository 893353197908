<template>
  <nav class="tw-grid tw-grid-cols-4 tw-justify-between">
    <V2NavLink v-for="link in links" :key="link.path" :link="link" />
  </nav>
</template>

<script setup lang="ts">
import FilledFiles from '~icons/fluent/folder-open-24-filled'
import OutlinedFiles from '~icons/fluent/folder-open-24-regular'
import FilledHome from '~icons/fluent/home-24-filled'
import OutlinedHome from '~icons/fluent/home-24-regular'
import FilledPerson from '~icons/fluent/person-24-filled'
import OutlinedPerson from '~icons/fluent/person-24-regular'
import FilledHelpIcon from '~icons/fluent/question-circle-24-filled'
import OutlinedHelpIcon from '~icons/fluent/question-circle-24-regular'

const HomeLink = {
  path: '/v2',
  text: 'Home',
  filledIcon: FilledHome,
  outlinedIcon: OutlinedHome,
}

const FilesLink = {
  path: '/v2/files',
  text: 'Files',
  filledIcon: FilledFiles,
  outlinedIcon: OutlinedFiles,
}

const HelpLink = {
  path: '/v2/help',
  text: 'Help',
  filledIcon: FilledHelpIcon,
  outlinedIcon: OutlinedHelpIcon,
}

const ProfileLink = {
  path: '/v2/profile',
  text: 'Profile',
  filledIcon: FilledPerson,
  outlinedIcon: OutlinedPerson,
}

const links = [HomeLink, FilesLink, HelpLink, ProfileLink]
</script>
