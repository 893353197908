<template>
  <footer
    class="tw-h-fit-content tw-w-full tw-flex-col tw-justify-self-center tw-pb-20 tw-text-xs tw-text-gray-600 md:tw-pb-0 lg:tw-left-0 lg:tw-right-0"
  >
    <hr />
    <div
      id="footer-div"
      class="tw-flex tw-w-full tw-flex-col tw-flex-wrap tw-justify-between tw-gap-2 tw-px-10 tw-py-4 tw-text-center lg:tw-flex-row lg:tw-items-start"
    >
      <p>Powered by Torticity © {{ new Date().getFullYear() }}</p>
      <div class="tw-mt-4 tw-flex tw-flex-wrap tw-items-center lg:tw-mt-0">
        <div class="tw-mx-auto tw-flex tw-flex-wrap tw-justify-center tw-gap-4">
          <NuxtLink
            to="https://torticity.com/legal/terms/"
            target="_blank"
            class="hover:tw-underline"
          >
            Terms of use
          </NuxtLink>
          <NuxtLink
            to="https://torticity.com/acceptable-use-policy"
            target="_blank"
            class="hover:tw-underline"
          >
            Acceptable use
          </NuxtLink>
          <NuxtLink
            to="https://torticity.com/disclaimer"
            target="_blank"
            class="hover:tw-underline"
          >
            Disclaimer
          </NuxtLink>
          <NuxtLink
            to="https://torticity.com/legal/privacy/"
            target="_blank"
            class="hover:tw-underline"
          >
            Privacy notice
          </NuxtLink>
          <NuxtLink
            to="https://torticity.com/legal/cookies"
            target="_blank"
            class="hover:tw-underline"
          >
            Cookie policy
          </NuxtLink>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
@media (min-width: 1024px) {
  #footer-div {
    justify-content: center;
  }
}
@media (min-width: 1080px) {
  #footer-div {
    justify-content: space-between;
  }
}
</style>
